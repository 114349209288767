
import { Vue, Component, Prop } from "vue-property-decorator";
import dateFormat from "dateformat";

@Component
export default class MonthlyReportContent extends Vue {
    @Prop({ type: String, default: "" }) readonly germanContent!: string;
    @Prop({ type: String, default: "" }) readonly germanSignature!: string;
    @Prop({ type: String, default: "" }) readonly englishContent!: string;
    @Prop({ type: String, default: "" }) readonly englishSignature!: string;

    get fullDate(): string {
        return dateFormat(new Date(), "dd.mm.yyyy", true);
    }

    get monthAndYearEnglish(): string {
        return dateFormat(new Date(), "mmmm yyyy");
    }

    get monthAndYearGerman(): string {
        const date = new Date();
        const formatter = new Intl.DateTimeFormat("de-DE", { month: "long" });
        const month = formatter.format(date);
        const year = dateFormat(date, "yyyy");
        return `${month} ${year}`;
    }
}
